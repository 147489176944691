import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

type Term = {
  heading: string
  paragraphs: string[]
}

type Props = {
  subheading: string
  terms: Term[]
}

const Section = styled.section`
  width: 100%;
  padding: 220px 0 60px 0;

  ${({ theme }) => theme.media.lg.min} {
    padding: 180px 0 60px 0;
  }
`

const TermsListWrapper = styled.section`
  width: 100%;
`

const TermsList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray100};
`

const TermsAndConditions: React.FC<Props> = ({ subheading, terms }) => {
  const { lg } = useBreakpoint()

  return (
    <Section title={subheading}>
      <Container slim={lg ? true : false}>
        <Heading
          as="h2"
          size={24}
          weight={700}
          transform="uppercase"
          dangerouslySetInnerHTML={{ __html: subheading }}
        />

        {terms.map(({ heading, paragraphs }, index) => (
          <TermsListWrapper key={heading} title={subheading}>
            <Heading
              as="h6"
              size={18}
              weight={700}
              dangerouslySetInnerHTML={{ __html: `${index + 1}. ${heading}` }}
            />
            <TermsList>
              {paragraphs.map((paragraph, idx) => (
                <Text
                  size={14}
                  weight={400}
                  margin="20px"
                  dangerouslySetInnerHTML={{
                    __html: `${idx + 1}. ${paragraph}`,
                  }}
                />
              ))}
            </TermsList>
          </TermsListWrapper>
        ))}
      </Container>
    </Section>
  )
}

export default TermsAndConditions
