import React from 'react'
import Layout from 'components/layout/layout'
import TermsAndConditions from 'components/layout/Sections/TermsAndConditions'

const termsContent = [
  {
    heading: 'Czym są pliki cookies?',
    paragraphs: [
      'Jest to niewielka informacja tekstowa zapisywana przez serwer na komputerze użytkownika w pliku cookie, którą serwer może odczytać przy ponownym połączeniu się z tego komputera. Pliki Cookie zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.',
    ],
  },
  {
    heading: 'Do czego używamy plików cookie?',
    paragraphs: [
      'Tworzenie statystyk, które pokazują, w jaki sposób Użytkownicy Serwisu korzystają ze strony internetowej, dzięki czemu możemy doskonalić jej strukturę oraz zawartość;',
      'Zagwarantowanie połączenia z odpowiednim serwisem na stronie internetowej, podczas wprowadzania jakichkolwiek modyfikacji w działaniu tej strony.',
    ],
  },
  {
    heading: 'Rodzaje plików cookie',
    paragraphs: [
      'W ramach Serwisu stosowane są dwa rodzaje plików cookie: „sesyjne” (session cookie) oraz „stałe” (persistent cookie). Cookie „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookie przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookie lub do czasu ich usunięcia przez Użytkownika.',
    ],
  },
  {
    heading: 'Jak ograniczyć lub usunąć pliki cookie z komputera?',
    paragraphs: [
      'Możesz ograniczyć lub usunąć pliki cookie zmieniając ustawienia swojej przeglądarki internetowej. Skorzystaj z pomocy w Twojej przeglądarce, aby dowiedzieć się jak to zrobić. Pamiętaj, że jeżeli usuniesz i zablokujesz pliki cookie z naszej strony internetowej, część strony może nie działać poprawnie.',
      'W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookie w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookie. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookie w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookie dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).',
      'Pliki cookie zamieszczane w urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą być również przez współpracujących z operatorem Serwisu reklamodawców oraz partnerów.',
    ],
  },
]

const TermsPage = () => {
  return (
    <Layout>
      <TermsAndConditions
        subheading="Polityka prywatności"
        terms={termsContent}
      />
    </Layout>
  )
}

export default TermsPage
